import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'


const Menu = (props) => (
    <nav id="menu">
        <div className="inner">
            <ul className="links">
                <li><Link onClick={props.onToggleMenu} to="/">Home</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/venue">The Venue</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/schedule">Schedule</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/lodging">Activities & Where to Stay</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/thecouple">The Couple</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/travel">Travel</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/registry">Registry</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/rsvp">RSVP</Link></li>
            </ul>
        </div>
        <a className="close" onClick={props.onToggleMenu} href="javascript:;">Close</a>
    </nav>
)

Menu.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Menu

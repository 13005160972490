import React from 'react'
import Layout from '../components/layout'
import Helmet from 'react-helmet'

import airbnb from '../assets/images/lodging/airbnb.png'
import oneonta from '../assets/images/lodging/oneonta.png'
import taxi from '../assets/images/lodging/taxi.png'

const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>S+J Activities & Lodging</title>
            <meta name="description" content="Lodging" />
        </Helmet>

        <div id="main" className="alt lodging">
            <section id="one">
                <div className="inner">
                <header className="major">
                        <h1>Activities</h1>
                    </header>
                     <p>Whether you're coming up on Friday or just have a few hours to kill on Saturday - there are plenty of activities in and around Oneonta and Summit</p>
                   
                     <h2 className="activities-headers">Near Oneonta </h2>

<div className="sectionWrapper">
                        <ul>
                            <li><a href="https://www.absolute-taxi.com/blog/top-10-awesome-things-to-do-in-oneonta-ny" target="_blank">Check out the top 10 things to do in Oneonta</a></li>
                            <li><a href="http://www.rootsbeeroneonta.com" target="_blank">Visit Roots Brewing Company</a></li>
                            <li><a href="https://www.alltrails.com/trail/us/new-york/table-rock-loop" target="_blank">Hike Table Rock loop - this is a 1.5 mile trails and is good for all skill levels</a></li>
                            <li><a href="https://www.colonialridgegolf.com/" target="_blank">Golf at Colonial Ridge</a></li>
                            <li>Head to Cooperstown (about 30 min from Oneonta) to check out the <a href="https://baseballhall.org/" target="_blank">Baseball Hall of Fame</a></li>
                            <li>Also in Cooperstown is the <a href="https://www.ommegang.com/" target="_blank">Ommegang brewery</a>, an authentic Belgian-style farmstead brewery that offers tours and tastings</li>
                        </ul>
</div>

                     <h2 className="activities-headers">Near Summit </h2>

<div className="sectionWrapper">
                        <ul>
                            <li><a href="http://kymarfarm.com/" target="_blank">Visit KyMar Farm Winery & Distillery</a> -  KyMar offers tastings and tours every Friday, Saturday and Sunday between 12pm - 5pm. Stop by to taste their vodka, shine, whiskey and wines!</li>
                           <li><a href="https://www.scrumpyewecider.com" target="_blank">Visit Scrumpy Ewe Cider</a> - a farm cidery that offers tastings</li>
                           <li>If you’re staying in Jefferson get breakfast at <a href="https://www.yelp.com/biz/the-breakfast-club-jefferson-2" target="_blank">The Breakfast Club</a></li>
                           <li><a href="https://www.stamfordgolfclub.com/" target="_blank">Golf at Stamford Golf Club</a></li>
                           <li>Head to Cobleskill (about 30 min from Summit) to <a href="https://www.secretcaverns.com" target="_blank">visit the SECRET CAVERNS (!) </a> and <a href="https://howecaverns.com/" target="_blank">Howe Caverns</a>. Secret Caverns is a bit less commercial than Howe Caverns, and includes a 100 ft. underground waterfall.</li>
                          </ul>
                           <h3>Summit Hiking</h3>
                           <ul>
                           <li><a href="https://local.aarp.org/jefferson-ny/hiking-trails/" target="_blank">There are many nearby trails around Jefferson</a></li> 
                           
                              <li>Vroman's Nose in Middleburgh (about 40 min from Summit) - hike up a mountain with a view of the valley. At the bottom of the trail is a tiny, <a href="http://underthenose.com/Food/" target="_blank">charming cafe called "Under the Nose"</a>. They have light lunches, homemade sweets, and locally crafted gifts. Really darn cute place to stop after a hike. </li>
                           <li><a href="https://goo.gl/maps/GN2x2CM5dHCpVoKD8" target="_blank">Giant's Ledge near Phoenicia (about an hour from Summit)</a> - then stop at the famous Phoenicia Diner or Woodstock Brewing for lunch</li>
                           <li><a href="https://www.alltrails.com/trail/us/new-york/kaaterskill-falls-trail" target="_blank">Kaaterskill Falls in Hunter </a> (about an hour from Summit) - views of one of the highest waterfalls in New York</li>
                         
                           </ul>
</div>
                    <header className="major">
                        <h1>Where to Stay</h1>
                    </header>

                    <p>We so appreciate you making the trip to (somewhat remote) Summit, NY for our wedding! Our goal is to make finding a place for you to stay and getting you to and from the venue as easy as possible.</p>
                    <p> Here are our top hotel and Airbnb choices. We’ll be providing shuttles from the Holiday Inn Express and Quality Inn in Oneonta to Seven Ponds on Saturday night.</p>

   

                    <div className="sectionWrapper">
                        <img className="airbnb" src={airbnb} alt="" />
                        <h2 className="lodging-headers">Airbnb</h2>
                    </div>

                    <div className="lodgingSection">

                        <p>There are many Airbnb's within a 30 minute drive of the farm - <a href="https://www.airbnb.com/wishlists/354526081/join?invite_code=JZIYNKXO&inviter_id=9350889" target="_blank" rel="noopener noreferrer" >check out a wishlist we created here</a> (you'll need an Airbnb account). </p>
       
                    </div>
                    <div className="sectionWrapper">
                        <img className="oneonta" src={oneonta} alt="" />
                        <h2 className="lodging-headers">Oneonta Hotels</h2>
                    </div>

                    <div className="lodgingSection">

                        <h3>Holiday Inn Express</h3>

                        <ul className="alt">
                            <li>Call to reserve or use the room block/group code "MDW" (Marz-Dozor Wedding) when booking online.</li>
                            <li>If you'd like, we'll have a shuttle running between the hotel and Seven Ponds on Saturday night.</li>
                            <li><a href="https://goo.gl/maps/ELAUXjBSsMo">141 Courtyard Dr, Oneonta, NY 13820</a></li>
                            <li><a href="https://www.hiexpress.com/oneontany">Website</a></li>
                        
                        </ul>

                        <h3>Quality Inn</h3>

                        <ul className="alt">
                            <li>Call to reserve and mention Marz/Dozor wedding block.</li>
                            <li>The shuttle will also pick up and drop off here on Saturday night.</li>
                            <li><a href="https://goo.gl/maps/AQeNvGKrNAS2">5206 NY-23, Oneonta, NY 13820</a></li>
                            <li><a href="https://www.choicehotels.com/new-york/oneonta/quality-inn-hotels/ny677?brand=QI">Website</a></li>
                        
                        </ul>

         
                        <p></p>

                        <h3>Even more Oneonta Hotels</h3>
                        <ul>
                            <li><a href="http://www.clariononeonta.com">Clarion Hotel Oneonta</a></li>
                            <li><a href="https://hamptoninn3.hilton.com/en/hotels/new-york/hampton-inn-oneonta-ONHNJHX/accommodations/index.html">Hampton Inn Oneonta</a></li>
                        </ul>
        
                    </div>
                    <div className="sectionWrapper">
                        <img className="taxi" src={taxi} alt="" />
                        <h2>Travel from your Airbnb or Hotel</h2>
                    </div>

                    <div className="lodgingSection">

                        <h3>Taxis</h3>

                        <ul className="alt">
                            <li>Unfortunately, UBER hasn’t made it out to Summit, NY yet so we recommend setting up rides with this taxi service if you aren’t planning to take the shuttle from Oneonta and don’t want to drive.</li>
                            <li><b>Absolute Taxi</b></li>
                            <li><a href="tel:6072874256">(607)-287-4256</a></li>
                            <li><a href="https://www.absolute-taxi.com/">Website</a></li>
                        </ul>

                        <h3>Shuttle Service</h3>

                        <ul className="alt">
                            <li>We’ll have a shuttle running from the Holiday Inn Express and Quality Inn to Seven Ponds on Saturday. More details to come!</li>
                        </ul>
        
                    </div>

                 </div>
            </section>
        </div>

    </Layout>
)

export default Generic